import React, { useEffect, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  changeMembership,
  updateBilling,
  updateUser,
} from '../state/login/thunks';
import { getErpMembershipContent, getMembershipPlans } from '../utils/api';
import Modal from './Modal';
import { toast } from 'react-toastify';
import CreditCardInfo from './creditcard/CreditCardModal';

const UpdateAndConnectShopifyModal = ({
  show,
  handleClose,
  profile,
  update,
  updateBilling,
  changeMembership,
}) => {
  const [shopUrl, setShopUrl] = useState(
    null
  );
  const [shopAccessToken, setShopAccessToken] = useState(
    null
  );
  const [errors, setErrors] = useState({
    shopUrlError: false,
    shopAccessTokenError: false,
  });
  const [standardPlusContent, setStandardPlusContent] = useState({});
  const [standardPlusPlan, setStandardPlusPlan] = useState({});
  const [stripe, setStripe] = useState(null);
  const [cardError, setCardError] = useState(undefined);
  const [isAddingCard, setIsAddingCard] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isConnectingShopify, setIsConnectingShopify] = useState(false);

  const [wordpressLoading, setWordpressLoading] = useState(false);
  const [wordpressData, setWordpressData] = useState();
  const fetchVideoPageData = async () => {
    setWordpressLoading(true);
    return fetch('https://content.gildform.com/wp-json/acf/v3/video_page/882')
      .then((data) => data.json())
      .then((data) => {
        setWordpressData(data);
        setWordpressLoading(false);
        return data;
      })
      .catch((err) => err);
  };

  useEffect(() => {
    fetchVideoPageData();
  }, []);

  const videoPageData = wordpressData
    ? wordpressData.acf.video_section[0]
    : null;

  const handleShopUrlChange = (value) => {
    if (value.includes('myshopify') || value.length === 0) {
      setShopUrl(value);
      setErrors({ ...errors, shopUrlError: false });
    } else {
      setErrors({ ...errors, shopUrlError: true });
    }
  };

  const fetchErpMembershipContent = async () => {
    const res = await getErpMembershipContent();
    setStandardPlusContent(res.find((item) => item.code === 'GOLD PLUS'));
  };

  const fetchMemberships = async () => {
    const memberships = await getMembershipPlans();
    setStandardPlusPlan(
      memberships
        ? memberships.data.find(
          (item) => item.metadata.nickname === 'Gold Plus'
        )
        : []
    );
  };

  useEffect(() => {
    fetchErpMembershipContent();
    fetchMemberships();
  }, []);

  const handleConnectShopify = async () => {
    setIsConnectingShopify(true);
    const obj = {
      ...profile,
      shopifyUrl: shopUrl ? shopUrl : profile ? profile.shopifyUrl : null,
      shopifyToken: shopAccessToken ? shopAccessToken : profile ? profile.shopifyToken : null,
    };
    const res = await update(obj);
    if (res) {
      setIsConnectingShopify(false);
      toast.success('Connected to shopify!', {
        className: 'bg-primary text-white',
        onClose: () => { },
      });
    } else {
      toast.error('An error occurred editing user data!');
    }
  };

  const handleAddCard = async () => {
    setIsAddingCard(true);
    if (!stripe) {
      return;
    }
    setCardError();
    const stripeResponse = await stripe.stripe.createToken(stripe.card);
    if (stripeResponse.error) {
      setCardError(stripeResponse.error.message);
      return;
    }
    const token = stripeResponse.token.id;
    const updatedBilling = await updateBilling(profile.id, token);
    if (updatedBilling) {
      setIsAddingCard(false);
      return updatedBilling;
    }
  };

  const handleUpdateMembership = async () => {
    setLoading(true);
    const res = await changeMembership(profile.id, standardPlusPlan);
    if (res.membership) {
      setIsAddingCard(false);
      setLoading(false);
      toast.success('Membership edited successfully!', {
        className: 'bg-primary text-white',
      });
    } else {
      toast.error('An error occurred editing membership!');
      setLoading(false);
    }
  };

  const updateUserReminder = async (never) => {
    const obj = never
      ? {
        ...profile,
        remind: 'no',
        remindTime: null,
      }
      : {
        ...profile,
        remind: 'yes',
        remindTime: new Date(),
      };
    const res = await update(obj);
    console.log('res  -----:>> ', res);
    if (res && !never) {
      toast.success('User reminder was set!', {
        className: 'bg-primary text-white',
        onClose: () => {
          handleClose();
        },
      });
      handleClose();
    }
    handleClose();
  };

  const membership = profile
    ? profile.membership
      ? profile.membership.metadata
        ? profile.membership.metadata.nickname
        : profile.membership.name
      : null
    : null;

  return (
    <Modal
      dashboardModal={true}
      show={show}
      title='Connect to Shopify and Upgrade Membership'
      handleClose={handleClose}
      dangerButtons={[]}
      size={'lg'}
      headerStyle={{ backgroundColor: '#7c6363', color: 'white' }}
      primaryButtons={[]}
      secondaryButtons={[
        {
          className: 'btn-do-not-show',
          title:
            'I will not have Shopify in the future. Do not show this again.',
          outline: true,
          handle: () => {
            updateUserReminder(true);
          },
        },
        {
          className: 'btn-remind-me-later',
          title: 'Remind me later.',
          outline: true,
          handle: () => {
            updateUserReminder(false);
          },
        },
      ]}
    >
      {wordpressLoading ? (
        <i className='fas fa-circle-notch fa-spin' />
      ) : (
        <div className='modal-container'>
          <div className='first-section'>
            <div className='video-container'>
              <span className='title'>How to start</span>
              <div
                className='video-frame'
                dangerouslySetInnerHTML={{
                  __html: videoPageData && videoPageData.video_source,
                }}
              />
            </div>
          </div>
          <div className='second-section'>
            {membership === 'Standard Plus' ||
              membership === 'STANDARD PLUS' ||
              membership === 'GOLD PLUS' ||
              membership === 'Gold Plus' ? (
              <div className='update-membership-section'>
                <div className='update-membership-title'>
                  <span className='title'>Membership</span>
                </div>
                <div className='membership-info'>
                  <span>Gold Plus</span>
                </div>
              </div>
            ) : (
              <div className='update-membership-section'>
                <div className='update-membership-title'>
                  <span className='title'>Upgrade to Gold Plus</span>
                </div>
                <div className='membership-card'>
                  <div className='card-header'>Gold Plus</div>
                  <div className='card-body'>
                    <p
                      className='card-text'
                      dangerouslySetInnerHTML={{
                        __html:
                          standardPlusContent && standardPlusContent.content,
                      }}
                    />
                  </div>
                  <div className='card-footer'>
                    <span className='billing-info'>
                      {`$ ${(standardPlusPlan.amount / 100).toFixed(
                        2
                      )} billed every ${standardPlusPlan.interval}`}
                    </span>
                  </div>
                </div>

                {profile && !profile.cardInfo && (
                  <>
                    <div className='credit-card-info'>
                      <CreditCardInfo
                        setStripe={setStripe}
                        initiateStripe={true}
                      />
                      {cardError !== void 0 && (
                        <span className='card-error'>{cardError}</span>
                      )}
                    </div>
                    <div className='update-and-show-card-info-button'>
                      <Button
                        className='update-button'
                        onClick={() => {
                          handleAddCard();
                        }}
                        disabled={isAddingCard}
                      >
                        {isAddingCard ? (
                          <i className='fas fa-circle-notch fa-spin' />
                        ) : (
                          'Add Card'
                        )}
                      </Button>
                    </div>
                  </>
                )}
                <div className='update-button-section'>
                  {(profile && profile.cardInfo) && (
                    <Button
                      className='update-button'
                      onClick={() => {
                        handleUpdateMembership();
                      }}
                      style={{
                        pointerEvents: (profile && !profile.cardInfo) ? 'none' : 'pointer',
                      }}
                      disabled={(profile && !profile.cardInfo) || loading}
                    >
                      {loading ? (
                        <i className='fas fa-circle-notch fa-spin' />
                      ) : (
                        'Update Membership'
                      )}
                    </Button>
                  )}
                </div>
              </div>
            )}
            <div className='shopify-section'>
              <div className='shopify-title'>
                <span className='title'>Connect to shopify</span>
              </div>
              <div className='shopify-inputs'>
                <div className='shop-url'>
                  <label className='shop-url-label'>Shopify Store URL</label>
                  <input
                    type='text'
                    name='shop-url'
                    placeholder={profile && profile.shopifyUrl || ''}
                    onChange={(e) => handleShopUrlChange(e.target.value)}
                  />
                </div>
                {errors.shopUrlError && (
                  <span className='shop-url-error'>
                    <div className=' text-danger '>
                      Your Store URL must contain myshopify.com for it to be
                      valid. (i.e. www.yoursite.myshopify.com) - please go to
                      <a
                        href={'https://support.gildform.com'}
                        target='_blank'
                        style={{ margin: '5px' }}
                      >
                        support.gildform.com
                      </a>
                      for more information.
                    </div>
                    <div className='w-50' />
                  </span>
                )}
                <div className='shop-access-token'>
                  <label className='shop-access-token-label'>
                    Shopify Access Token
                  </label>
                  <input
                    type='text'
                    name='shop-access-token'
                    placeholder={profile && profile.shopifyToken || ''}
                    onChange={(e) => setShopAccessToken(e.target.value)}
                  />
                </div>
              </div>
              <div className='shopify-button-section'>
                {!(
                  membership === 'Standard Plus' ||
                  membership === 'STANDARD PLUS' ||
                  membership === 'GOLD PLUS' ||
                  membership === 'Gold Plus'
                ) ? (
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip id='tooltip-top'>Upgrade to Gold Plus</Tooltip>
                    }
                  >
                    <span>
                      <Button
                        className='connect-button'
                        onClick={() => {
                          handleConnectShopify();
                        }}
                        style={{
                          pointerEvents: !(
                            membership === 'GOLD PLUS' ||
                            membership === 'Gold Plus' ||
                            membership === 'Standard Plus' ||
                            membership === 'STANDARD PLUS'
                          )
                            ? 'none'
                            : 'pointer',
                        }}
                        disabled={
                          (!shopAccessToken || profile && profile.shopifyToken) ||
                          (!shopUrl || profile && profile.shopifyUrl) ||
                          !(
                            membership === 'GOLD PLUS' ||
                            membership === 'Gold Plus' ||
                            membership === 'Standard Plus' ||
                            membership === 'STANDARD PLUS'
                          ) ||
                          isConnectingShopify
                        }
                      >
                        {isConnectingShopify ? (
                          <i className='fas fa-circle-notch fa-spin' />
                        ) : (
                          'Connect'
                        )}
                      </Button>
                    </span>
                  </OverlayTrigger>
                ) : (
                  <Button
                    className='connect-button'
                    onClick={() => {
                      handleConnectShopify();
                    }}
                    style={{
                      pointerEvents: !(
                        membership === 'GOLD PLUS' ||
                        membership === 'Gold Plus' ||
                        membership === 'Standard Plus' ||
                        membership === 'STANDARD PLUS'
                      )
                        ? 'none'
                        : 'pointer',
                    }}
                    disabled={
                      (!shopAccessToken || profile && profile.shopifyToken) ||
                      (!shopUrl || profile && profile.shopifyUrl) ||
                      !(
                        membership === 'GOLD PLUS' ||
                        membership === 'Gold Plus' ||
                        membership === 'Standard Plus' ||
                        membership === 'STANDARD PLUS'
                      ) ||
                      isConnectingShopify
                    }
                  >
                    {isConnectingShopify ? (
                      <i className='fas fa-circle-notch fa-spin' />
                    ) : (
                      'Connect'
                    )}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  update: (user) => dispatch(updateUser(user)),
  changeMembership: (userID, membership) =>
    dispatch(changeMembership(userID, membership)),
  updateBilling: (userID, token) => dispatch(updateBilling(userID, token)),
});

export default connect(
  null,
  mapDispatchToProps
)(UpdateAndConnectShopifyModal);
