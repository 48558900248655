import React from 'react';
import { Button } from 'react-bootstrap';
import { navigate } from '@reach/router';

const ButtonLink = ({
  internal_link,
  title,
  external_link,
  custom_link,
  className,
  size,
  index,
}) => {
  if (internal_link) {
    const linkParts = internal_link.split('/');
    const pageSlug = linkParts[linkParts.length - 1];
    return (
      <Button href={pageSlug} className={className} size={size}>
        {title}
      </Button>
    );
  } else if (custom_link) {
    return (
        <Button
          className={className}
          size={size}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`${custom_link}`)
          }}
        >
          {title}
        </Button>
    );
  } else {
    return (
      <Button
        href={external_link}
        target='__blank'
        className={className}
        size={size}
      >
        {title}
      </Button>
    );
  }
};

export default ButtonLink;
